import JustValidate from 'just-validate';
import Inputmask from 'inputmask';
import tippy from 'tippy.js';

function formManager() {
  const root = document.querySelector('.booking');
  const form = document.querySelector('.form');
  const inputs = form.querySelectorAll('.form__input');
  const inputTel = form.querySelector('input[type="tel"]');
  const checkbox = form.querySelector('.custom-checkbox__input');
  const submitBtn = form.querySelector('.form__btn');
  const im = new Inputmask('+7 (999) 999-99-99');
  const caption = document.querySelector('.booking__title');
  const sentNotice = document.querySelector('.booking__sent');
  const customSelect = document.querySelector('.form__select');
  const selectTrigger = customSelect.querySelector('.form__select-trigger');
  const optionItems = customSelect.querySelectorAll('.form__option');
  im.mask(inputTel);

  const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  tippy('#tooltip', {
    content: 'Будние дни и выходные с 7:30 до 00:00',
    theme: 'main',
  });

  inputs.forEach((e) => {
    if (e.matches('.name-input')) {
      e.addEventListener('input', () => {
        e.value = capitalize(e.value);
        e.value = e.value.replace(/[^A-Za-zА-Яа-яЁё\s]/g, '');
        e.value = e.value.substr(0, 50);
      });
    }
    if (e.matches('.amount-input')) {
      e.addEventListener('input', () => {
        e.value = e.value.replace(/[\D]+/g, '');
        e.value = e.value.substr(0, 2);
      });
    }
    if (e.matches('.wishes-input')) {
      e.addEventListener('input', () => {
        e.value = capitalize(e.value);
        e.value = e.value.replace(/[^A-Za-zА-Яа-яЁё0-9\s]/g, '');
        e.value = e.value.substr(0, 500);
      });
    }
    if (e.matches('.tel-input')) {
      e.setAttribute('inputmode', 'tel');
    }
    e.addEventListener('focus', () => {
      e.closest('.form__wrap').classList.add('active');
    });
    e.addEventListener('blur', () => {
      if (!e.value) {
        e.closest('.form__wrap').classList.remove('active');
      }
    });
  });
  checkbox.addEventListener('change', () => {
    submitBtn.disabled = true;
    if (checkbox.checked) {
      submitBtn.disabled = false;
    }
  });
  //Выпадающий список
  selectTrigger.addEventListener('click', () => {
    customSelect.classList.toggle('active');
  });
  optionItems.forEach(option => {
    option.addEventListener('click', () => {
      selectTrigger.textContent = option.textContent;
      customSelect.classList.remove('active');
    });
  });
  document.addEventListener('click', event => {
    if (!customSelect.contains(event.target)) {
      customSelect.classList.remove('active');
    }
  });
  const validation = new JustValidate('.form', {
    errorFieldCssClass: 'is-invalid',
    errorFieldStyle: {
      color: '#C20000',
      border: '10px solid #C20000',
    },
    errorLabelCssClass: 'is-label-invalid',
    errorLabelStyle: {
      color: '#C20000',
      textDecoration: 'underlined',
    },
    focusInvalidField: false,
    lockForm: true,
    errorsContainer: '.form__validation-errors',
  });
  validation
    .addField('#tel', [
      {
        validator: () => {
          const unMask = inputTel.inputmask.unmaskedvalue();
          if (unMask.length < 10) {
            return false;
          }
          return true;
        },
        errorMessage: 'Укажите корректный номер',
      },
    ])
    .addField('#adress', [
      {
        validator: () => {
          if (selectTrigger.textContent.length < 1) {
            return false;
          }
          return true;
        },
        errorMessage: 'Укажите адрес',
      },
    ])
    .onSuccess((event) => {
      inputs.forEach((e) => {
        if (e.matches('.date-input')) {
          if (e.value) {
            if ((new Date(e.value) - new Date()) < 0) {
              e.type = 'text';
              e.value = null;
            }
          }
        }
        if (!e.value) {
          e.type = 'text';
          e.value = 'Уточнить у заказчика';
        }
      });
      const formData = new FormData(event.target);
      formData.append('Адрес', selectTrigger.textContent);
      const xhr = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            console.log('Отправлено');
          }
        }
      };
      console.log(formData);
      xhr.open('POST', 'telegram.php', true);
      
      xhr.send(formData);
      event.target.reset();
      root.classList.add('sent');
      setTimeout(() => {
        form.classList.add('hide');
        caption.classList.add('hide');
        sentNotice.classList.add('view');
      }, 300);
    });
}
export default formManager;
